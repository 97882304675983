import axios from "axios";
import shortid from "shortid";
import {
  GET_CHARGING_STATION_SETTINGS_REQUEST_STATE,
  GET_CHARGING_STATION_TRANSACTIONS_REQUEST_STATE,
  LAST_SETTLED_TRANSACTION_REQUEST_STATE,
  LATEST_STATUS_REQUEST_STATE,
  SET_CHARGING_STATION_SETTINGS_REQUEST_STATE,
  SET_REMOTE_START_REQUEST_STATE,
  SET_REMOTE_STOP_REQUEST_STATE,
} from "../types/types";
const api = axios.create({
  baseURL: "https://api.daheimladen.com/v1/",
});

export const getChargingStations = () => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        api
          .get(`/cs/get_cs`, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            var chargingStations = res.data;
            chargingStations.map((cs) => {
              cs.unqid = shortid.generate();
              cs.isLocal = false;
              return cs;
            });

            dispatch({
              type: "GET_CHARGING_STATION_SUCCESS",
              payload: chargingStations,
            });
          })
          .catch((err) => {
            dispatch({
              type: "GET_CHARGING_STATION_ERROR",
              payload: err,
            });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const deleteChargingStation = (unqid) => {
  return (dispatch, getState, getFirebase) => {
    const { chargingStations } = getState().chargingStation;
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;

    const chargingStationToBeDeleted = chargingStations.find(
      (cs) => cs.unqid === unqid
    );

    if (chargingStationToBeDeleted.isLocal === false) {
      currentUser.getIdToken(true).then((idToken) => {
        api
          .delete(
            `/cs/${chargingStationToBeDeleted.charging_station_id}/delete_cs`,
            {
              headers: { Authorization: `Bearer ${idToken}` },
            }
          )
          .then((res) => {
            const reducedStations = chargingStations.filter(
              (cs) => cs.charging_station_id !== res.data.charging_station_id
            );
            dispatch({
              type: "DELETE_CHARGING_STATION_SUCCESS",
              payload: reducedStations,
            });
          })
          .catch((err) => {
            dispatch({
              type: "DELETE_CHARGING_STATION_ERROR",
              payload: err,
            });
          });
      });
    } else {
      const reducedStations = chargingStations.filter(
        (cs) => cs.unqid !== unqid
      );
      dispatch({
        type: "DELETE_CHARGING_STATION_SUCCESS",
        payload: reducedStations,
      });
    }
  };
};

export const appendChargingStation = (cs) => {
  return (dispatch, getState, getFirebase) => {
    const { chargingStations } = getState().chargingStation;
    cs.unqid = shortid.generate();
    cs.isLocal = true;
    dispatch({
      type: "APPEND_CHARGING_STATION",
      payload: [...chargingStations, cs],
    });
  };
};

export const getLastSettledTransaction = (chargingStationID) => {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: LAST_SETTLED_TRANSACTION_REQUEST_STATE, payload: true });
    const firebase = getFirebase();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        api
          .get(`/cs/${chargingStationID}/last_settled_transaction`, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            dispatch({
              type: LAST_SETTLED_TRANSACTION_REQUEST_STATE,
              payload: false,
            });
            dispatch({
              type: "GET_LAST_SETTLED_TRANSACTION_SUCCESS",
              payload: res.data,
            });
          })
          .catch((err) => {
            console.log(err);
            dispatch({
              type: LAST_SETTLED_TRANSACTION_REQUEST_STATE,
              payload: false,
            });
          });
      });
  };
};

export const getLatestStatus = (chargingStationID) => {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: LATEST_STATUS_REQUEST_STATE, payload: true });
    const firebase = getFirebase();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        api
          .get(`/cs/${chargingStationID}/status`, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            dispatch({ type: LATEST_STATUS_REQUEST_STATE, payload: false });
            dispatch({
              type: "GET_STATUS_SUCCESS",
              payload: res.data,
            });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: LATEST_STATUS_REQUEST_STATE, payload: false });
            dispatch({
              type: "GET_STATUS_ERROR",
              payload: err,
            });
          });
      });
  };
};

export const startCharging = (chargingStationID) => {
  return (dispatch, getState, getFirebase) => {
    dispatch({
      type: SET_REMOTE_START_REQUEST_STATE,
      payload: true,
    });
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    const data = {
      connector_id: 1,
      idtag: currentUser.uid.substring(0, 10),
    };
    currentUser.getIdToken(true).then((idToken) => {
      api
        .post(`/cs/${chargingStationID}/remotestart`, data, {
          headers: { Authorization: `Bearer ${idToken}` },
        })
        .then((res) => {
          dispatch({
            type: SET_REMOTE_START_REQUEST_STATE,
            payload: false,
          });
          dispatch({ type: "REMOTE_START_SUCCESS", payload: res.data });
        })
        .catch((err) => {
          dispatch({
            type: SET_REMOTE_START_REQUEST_STATE,
            payload: false,
          });
          dispatch({ type: "REMOTE_START_ERROR", payload: err });
        });
    });
  };
};

export const stopCharging = (chargingStationID, transactionID) => {
  return (dispatch, getState, getFirebase) => {
    dispatch({
      type: SET_REMOTE_STOP_REQUEST_STATE,
      payload: true,
    });
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    const data = {
      transaction_id: transactionID,
    };
    currentUser.getIdToken(true).then((idToken) => {
      api
        .post(`/cs/${chargingStationID}/remotestop`, data, {
          headers: { Authorization: `Bearer ${idToken}` },
        })
        .then((res) => {
          dispatch({
            type: SET_REMOTE_STOP_REQUEST_STATE,
            payload: false,
          });
          dispatch({ type: "REMOTE_STOP_SUCCESS", payload: res.data });
        })
        .catch((err) => {
          dispatch({
            type: SET_REMOTE_STOP_REQUEST_STATE,
            payload: false,
          });
          dispatch({ type: "REMOTE_STOP_ERROR", payload: err });
        });
    });
  };
};

export const getLatestInProgressTransaction = (chargingStationID) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    currentUser.getIdToken(true).then((idToken) => {
      api
        .get(`/cs/${chargingStationID}/get_latest_inprogress_transaction`, {
          headers: { Authorization: `Bearer ${idToken}` },
        })
        .then((res) => {
          dispatch({
            type: "GET_LATEST_IN_PROGRESS_TRANSACTION_SUCCESS",
            payload: res.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: "GET_LATEST_IN_PROGRESS_TRANSACTION_ERROR",
            payload: err,
          });
        });
    });
  };
};

export const getAllChargingStationTransactions = (
  chargingStationID,
  idtag,
  fromMon,
  fromYear,
  toMon,
  toYear
) => {
  return (dispatch, getState, getFirebase) => {
    dispatch({
      type: GET_CHARGING_STATION_TRANSACTIONS_REQUEST_STATE,
      payload: true,
    });
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    const params = new URLSearchParams();
    if (idtag !== undefined && idtag !== null && idtag !== "") {
      params.append("idtag", idtag);
    }
    if (fromMon !== undefined && fromMon !== null && fromMon !== "") {
      params.append("from-mon", fromMon);
    }
    if (fromYear !== undefined && fromYear !== null && fromYear !== "") {
      params.append("from-year", fromYear);
    }
    if (toMon !== undefined && toMon !== null && toMon !== "") {
      params.append("to-mon", toMon);
    }
    if (toYear !== undefined && toYear !== null && toYear !== "") {
      params.append("to-year", toYear);
    }
    var url =
      chargingStationID !== "all"
        ? `cs/${chargingStationID}/get_chargingstation_transactions?${params}`
        : `cs/get_transactions_of_all_chargers?${params}`;
    currentUser.getIdToken(true).then((idToken) => {
      api
        .get(url, {
          headers: { Authorization: `Bearer ${idToken}` },
        })
        .then((res) => {
          dispatch({
            type: GET_CHARGING_STATION_TRANSACTIONS_REQUEST_STATE,
            payload: false,
          });
          dispatch({
            type: "GET_CHARGING_STATION_TRANSACTIONS_SUCCESS",
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: GET_CHARGING_STATION_TRANSACTIONS_REQUEST_STATE,
            payload: false,
          });
          dispatch({
            type: "GET_CHARGING_STATION_TRANSACTIONS_ERROR",
            payload: err,
          });
        });
    });
  };
};

export const downloadTransactionsCSV = (
  chargingStationID,
  idtag,
  fromMon,
  fromYear,
  toMon,
  toYear
) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    const params = new URLSearchParams();
    if (idtag !== undefined && idtag !== null && idtag !== "") {
      params.append("idtag", idtag);
    }
    if (fromMon !== undefined && fromMon !== null && fromMon !== "") {
      params.append("from-mon", fromMon);
    }
    if (fromYear !== undefined && fromYear !== null && fromYear !== "") {
      params.append("from-year", fromYear);
    }
    if (toMon !== undefined && toMon !== null && toMon !== "") {
      params.append("to-mon", toMon);
    }
    if (toYear !== undefined && toYear !== null && toYear !== "") {
      params.append("to-year", toYear);
    }
    params.append("lang", "de");
    var url =
      chargingStationID !== "all"
        ? `cs/${chargingStationID}/get_chargingstation_transactions_csv?${params}`
        : `cs/get_transactions_csv_of_all_chargers?${params}`;
    currentUser.getIdToken(true).then((idToken) => {
      api
        .get(url, {
          headers: { Authorization: `Bearer ${idToken}` },
          responseType: "blob",
        })
        .then((res) => {
          console.log(res.headers);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `transactions-${chargingStationID}.csv`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
};

export const downloadTransactionsDPF = (
  chargingStationID,
  idtag,
  fromMon,
  fromYear,
  toMon,
  toYear
) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    const params = new URLSearchParams();
    if (idtag !== undefined && idtag !== null && idtag !== "") {
      params.append("idtag", idtag);
    }
    if (fromMon !== undefined && fromMon !== null && fromMon !== "") {
      params.append("from-mon", fromMon);
    }
    if (fromYear !== undefined && fromYear !== null && fromYear !== "") {
      params.append("from-year", fromYear);
    }
    if (toMon !== undefined && toMon !== null && toMon !== "") {
      params.append("to-mon", toMon);
    }
    if (toYear !== undefined && toYear !== null && toYear !== "") {
      params.append("to-year", toYear);
    }
    params.append("lang", "de");
    var url =
      chargingStationID !== "all"
        ? `cs/${chargingStationID}/get_transactions_pdf?${params}`
        : `cs/get_transactions_pdf_of_all_chargers?${params}`;
    currentUser.getIdToken(true).then((idToken) => {
      api
        .get(url, {
          headers: { Authorization: `Bearer ${idToken}` },
          responseType: "blob",
        })
        .then((res) => {
          console.log(res.headers);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `transactions-${chargingStationID}.pdf`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
};

export const getChargingCurrent = (chargingStationID) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    currentUser.getIdToken(true).then((idToken) => {
      const data = {
        key: "ChargeRate",
      };
      api
        .post(`/cs/${chargingStationID}/get_config`, data, {
          headers: { Authorization: `Bearer ${idToken}` },
        })
        .then((res) => {
          dispatch({
            type: "GET_CHARGE_RATE_SUCCESS",
            payload: res.data.value,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: "GET_CHARGE_RATE_ERROR",
            payload: null,
          });
        });
    });
  };
};

export const setChargingCurrent = (chargingStationID, chargingCurrent) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    currentUser.getIdToken(true).then((idToken) => {
      const data = {
        charging_station_id: chargingStationID,
        key: "ChargeRate",
        value: chargingCurrent,
      };
      api
        .post(`/cs/${chargingStationID}/change_config`, data, {
          headers: { Authorization: `Bearer ${idToken}` },
        })
        .then((res) => {
          dispatch({
            type: "SET_CHARGE_RATE_SUCCESS",
            payload: res.data.status,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: "SET_CHARGE_RATE_ERROR",
            payload: err,
          });
        });
    });
  };
};

export const setChargingStationErrorsToNull = () => {
  return (dispatch, getState, getFirebase) => {
    dispatch({
      type: "SET_CHARGING_STATION_ERROR__TO_NULL",
      payload: null,
    });
  };
};

const getFromToMonYearPair = (numLastMonths) => {
  const months = [
    "_",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const currentDate = new Date();
  // getMonth gives 0 for Jan, 1 for Feb and so on...
  // We get the last sixth month index and use it as an array index for `months`
  const fromDate = new Date();
  fromDate.setMonth(currentDate.getMonth() - numLastMonths);
  const toDate = new Date();
  return {
    fromMon: months[fromDate.getMonth() + 1],
    fromYear: fromDate.getFullYear(),
    toMon: months[toDate.getMonth() + 1],
    toYear: toDate.getFullYear(),
  };
};

export const getChargingStationPowerUtilization = (chargingStationID) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    const queryMonYear = getFromToMonYearPair(12);
    currentUser.getIdToken(true).then((idToken) => {
      api
        .get(
          `/cs/${chargingStationID}/get_chargingstation_power_consumption_by_month?from-mon=${queryMonYear.fromMon}&from-year=${queryMonYear.fromYear}&to-mon=${queryMonYear.toMon}&to-year=${queryMonYear.toYear}`,
          {
            headers: { Authorization: `Bearer ${idToken}` },
          }
        )
        .then((res) => {
          dispatch({
            type: "GET_CHARGING_STATION_POWER_UTILIZATION",
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: "GET_CHARGING_STATION_POWER_UTILIZATION_ERROR",
            payload: err,
          });
        });
    });
  };
};

export const getChargingStationSettings = (chargingStationID) => {
  return (dispatch, getState, getFirebase) => {
    dispatch({
      type: GET_CHARGING_STATION_SETTINGS_REQUEST_STATE,
      payload: true,
    });
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    currentUser.getIdToken(true).then((idToken) => {
      api
        .get(`/cs/${chargingStationID}/get_settings`, {
          headers: { Authorization: `Bearer ${idToken}` },
        })
        .then((res) => {
          dispatch({
            type: GET_CHARGING_STATION_SETTINGS_REQUEST_STATE,
            payload: false,
          });
          dispatch({
            type: "GET_CHARGING_STATION_SETTINGS",
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: GET_CHARGING_STATION_SETTINGS_REQUEST_STATE,
            payload: false,
          });
          dispatch({
            type: "GET_CHARGING_STATION_SETTINGS_ERROR",
            payload: err,
          });
        });
    });
  };
};

export const setChargingStationSettings = (chargingStationID, costPerKwh) => {
  return (dispatch, getState, getFirebase) => {
    dispatch({
      type: SET_CHARGING_STATION_SETTINGS_REQUEST_STATE,
      payload: true,
    });
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    const data = {
      charging_station_id: chargingStationID,
      cost_per_kwh: parseFloat(costPerKwh),
    };
    currentUser.getIdToken(true).then((idToken) => {
      api
        .post(`/cs/${chargingStationID}/set_settings`, data, {
          headers: { Authorization: `Bearer ${idToken}` },
        })
        .then((res) => {
          dispatch({
            type: SET_CHARGING_STATION_SETTINGS_REQUEST_STATE,
            payload: false,
          });
          dispatch({
            type: "SET_CHARGING_STATION_SETTINGS",
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: SET_CHARGING_STATION_SETTINGS_REQUEST_STATE,
            payload: false,
          });
          dispatch({
            type: "SET_CHARGING_STATION_SETTINGS_ERROR",
            payload: err,
          });
        });
    });
  };
};

export const saveCards = (chargingStationID, cards) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    currentUser.getIdToken(true).then((idToken) => {
      axios
        .all(
          cards.map((card) => {
            var cardReq = {
              charging_station_id: chargingStationID,
              idtag: card.idtag,
              alias: card.alias,
              status: card.status,
            };
            if (card.uid !== undefined) {
              cardReq.uid = card.uid;
            }
            return api.post(`/cs/${chargingStationID}/save_card`, cardReq, {
              headers: { Authorization: `Bearer ${idToken}` },
            });
          })
        )
        .then((resArr) => {
          dispatch({
            type: "SAVE_CARDS_SUCESS",
          });
          dispatch(getCards(chargingStationID));
        })
        .catch((err) => {
          dispatch({
            type: "SAVE_CARDS_ERROR",
            payload: err,
          });
        });
    });
  };
};

export const getCards = (chargingStationID) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    var url =
      chargingStationID !== "all"
        ? `/cs/${chargingStationID}/get_cards`
        : `/cs/get_cards_off_all_chargers`;
    currentUser.getIdToken(true).then((idToken) => {
      api
        .get(url, {
          headers: { Authorization: `Bearer ${idToken}` },
        })
        .then((res) => {
          var cards = res.data;
          cards.map((card) => (card.unqid = shortid.generate()));
          dispatch({
            type: "GET_CARDS",
            payload: cards,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: "GET_CARDS_ERROR",
            payload: err,
          });
        });
    });
  };
};

export const deleteRFIDCard = (unqid) => {
  return (dispatch, getState, getFirebase) => {
    const { cards } = getState().chargingStation;
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;

    const cardToBeDeleted = cards.find((card) => card.unqid === unqid);

    if (cardToBeDeleted.uid !== undefined) {
      currentUser.getIdToken(true).then((idToken) => {
        api
          .delete(`/cs/delete_card/${cardToBeDeleted.uid}`, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            const reducedCards = cards.filter(
              (card) => card.uid !== res.data.uid
            );
            dispatch({
              type: "DELETE_CARD_SUCCESS",
              payload: reducedCards,
            });
          })
          .catch((err) => {
            dispatch({
              type: "DELETE_CARD_ERROR",
              payload: err,
            });
          });
      });
    } else {
      const reducedCards = cards.filter((card) => card.unqid !== unqid);
      dispatch({
        type: "DELETE_CARD_SUCCESS",
        payload: reducedCards,
      });
    }
  };
};

export const appendCard = (card) => {
  return (dispatch, getState, getFirebase) => {
    const { cards } = getState().chargingStation;
    card.unqid = shortid.generate();
    dispatch({
      type: "APPEND_CARD",
      payload: [...cards, card],
    });
  };
};

export const saveChargingTimers = (chargingStationID, timers) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    currentUser.getIdToken(true).then((idToken) => {
      axios
        .all(
          timers.map((timer) => {
            var timerReq = {
              start_charging_at: timer.start_charging_at,
              stop_charging_at: timer.stop_charging_at,
            };
            if (timer.uid !== undefined) {
              timerReq.uid = timer.uid;
            }
            return api.post(`/cs/${chargingStationID}/add_timer`, timerReq, {
              headers: { Authorization: `Bearer ${idToken}` },
            });
          })
        )
        .then((resArr) => {
          dispatch({
            type: "SAVE_TIMERS_SUCCESS",
          });
          dispatch(getChargingTimers(chargingStationID));
        })
        .catch((err) => {
          dispatch({
            type: "SAVE_TIMERS_ERROR",
            payload: err,
          });
        });
    });
  };
};

export const getChargingTimers = (chargingStationID) => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    currentUser.getIdToken(true).then((idToken) => {
      api
        .get(`/cs/${chargingStationID}/get_timers`, {
          headers: { Authorization: `Bearer ${idToken}` },
        })
        .then((res) => {
          var timers = res.data;
          console.log(timers);
          timers.map((timer) => (timer.unqid = shortid.generate()));
          dispatch({
            type: "GET_TIMERS",
            payload: timers,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: "GET_TIMERS_ERROR",
            payload: err,
          });
        });
    });
  };
};

export const deleteChargingTimer = (unqid) => {
  return (dispatch, getState, getFirebase) => {
    const { timers } = getState().chargingStation;
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;

    const timerToBeDeleted = timers.find((timer) => timer.unqid === unqid);

    if (timerToBeDeleted.uid !== undefined) {
      currentUser.getIdToken(true).then((idToken) => {
        api
          .delete(`/cs/delete_timer/${timerToBeDeleted.uid}`, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then((res) => {
            const reducedTimers = timers.filter(
              (timer) => timer.uid !== res.data.uid
            );
            dispatch({
              type: "DELETE_TIMER_SUCCESS",
              payload: reducedTimers,
            });
          })
          .catch((err) => {
            dispatch({
              type: "DELETE_TIMER_ERROR",
              payload: err,
            });
          });
      });
    } else {
      const reducedTimers = timers.filter((timer) => timer.unqid !== unqid);
      dispatch({
        type: "DELETE_TIMER_SUCCESS",
        payload: reducedTimers,
      });
    }
  };
};

export const appendTimer = (timer) => {
  return (dispatch, getState, getFirebase) => {
    const { timers } = getState().chargingStation;
    timer.unqid = shortid.generate();
    dispatch({
      type: "APPEND_TIMER",
      payload: [...timers, timer],
    });
  };
};
